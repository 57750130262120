import ComLayout from '@/layout'

const activityRouter = {
  path: '/activity-management',
  component: ComLayout,
  redirect: '/activity',
  meta: { title: 'activityManagement' },
  children: [
    {
      path: '/activity',
      component: () => import('@/views/activity'),
      name: 'Activity',
      meta: { title: 'activity' }
    },
    {
      path: '/activity/detail/:id',
      component: () => import('@/views/activity/detail'),
      name: 'ActivityDetail',
      meta: { title: 'activityDetail' },
      props: true
    }
  ]
}

export default activityRouter
