<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="456px"
      :before-close="dialogBeforeClose"
      custom-class="login-dialog">
      <div v-if="type === 0">
        <div class="logo-box"><img src="@/assets/header/logo-color.svg" alt=""></div>
        <div class="dialog-title">登录</div>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="username">
            <el-input v-model="form.username" placeholder="账号" type="text" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="form.password" placeholder="密码" @keyup.enter.native="handleLogin" type="password" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
        <div @click="handleLogin" class="login-btn">
          <div><span>登 录</span></div>
        </div>
        <div @click="handleSignIn" class="login-btn sing-in">
          <div>单 点 登 录</div>
        </div>
        <div class="register">
          <div @click="handleType(1)">
            <span>手机号登录/注册账号</span>
          </div>
        </div>
      </div>
      <div v-if="type === 1">
        <div class="logo-box"><img src="@/assets/header/logo-color.svg" alt=""></div>
        <div class="dialog-title">注 册</div>
        <div>
          <el-form ref="registerForm" :model="registerForm" :rules="registerRule">
            <el-form-item prop="phone">
              <el-input v-model="registerForm.phone" placeholder="请输入手机号" ></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="16">
                <el-form-item prop="code">
                  <el-input v-model="registerForm.code" placeholder="请输入验证码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-button type="primary" @click="getCodeHandle">{{getShow()}}</el-button>
              </el-col>
            </el-row>
          </el-form>
          <div v-if="registerForm.phone" class="form-text">默认登录密码为 {{defaultPwd}}, 注册后请尽快修改!!!</div>
        </div>
        <div @click="handleRegister" class="login-btn">
          <div><span>提 交</span></div>
        </div>
         <div class="register">
          <div>
            <!-- <span>首次登录手机号会自动注册</span> -->
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUrlParams } from '@/utils/method.js'
import { getSMSCodeByPhone, smsLoginByCode } from '@/api/login-zhiya.js'
// import { setToken } from '@/utils/auth.js'

export default {
  name: 'UserLogin',
  data () {
    return {
      dialogVisible: false,
      defaultPwd: null,
      user: {},
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, trigger: 'blur', message: '请输入您的账号' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入您的密码' }
        ]
      },
      registerForm: {},
      registerRule: {},
      uuid: '',
      countdown: 60, // 倒计时数字
      isCountingDown: false, // 是否正在进行倒计时
      type: 1 // 0 登录 1 注册
    }
  },
  computed: {
    ...mapGetters([
      'token',
      'cas_token'
    ])
  },
  created () {
    this.dialogVisible = true
    this.getConfigKey('sys.user.initPassword').then(res => {
      console.log('res', res)
      this.defaultPwd = res.msg
    })
  },
  mounted () {
    // 单点登录消息监听
    window.addEventListener('message', (e) => {
      if (e?.data?.message && e?.data?.message?.type === 'cas') {
        if (!this.token || !this.cas_token) {
          const params = getUrlParams(e.data.message.value)
          if (params.code && params.state) {
            this.$store.dispatch('user/GetPlatformTOken', {
              code: params.code,
              state: params.state
            })
              .then((res) => {
                if (res.code === 200) this.$emit('closeDialog')
              })
          }
        }
      }
    })
  },
  methods: {
    // 关闭登录页面
    dialogBeforeClose () {
      this.dialogVisible = false
      this.$emit('closeDialog')
    },
    // 登录
    handleLogin () {
      if (this.form.username === '' || this.form.password === '') {
        this.$modal.msgError('账号密码不能为空')
        return
      }
      this.$store.dispatch('user/Login', this.form)
        .then(() => {
          this.$store.dispatch('user/GetInfo')
          this.dialogVisible = false
        })
        .catch()
    },
    // 单点登录
    handleSignIn () {
      this.dialogVisible = false
      window.open(
        this.getSigninUrl(),
        'newwindow',
        'height=700,width=1000'
      )
    },
    // 登录、注册转换
    handleType (type) {
      this.type = type
    },
    // 获取验证码
    getCodeHandle () {
      // 已在倒计时中
      if (this.isCountingDown) {
        return
      }
      if (!this.validatePhoneNumber()) {
        return
      }
      const data = {
        phone: this.registerForm.phone
      }
      getSMSCodeByPhone(data).then(res => {
        // console.log('获取验证码', res)
        this.uuid = res.uuid
        this.$modal.msgSuccess('验证码发送成功')
        this.startCountdown()
      })
    },
    // 开始倒计时
    startCountdown () {
      // eslint-disable-next-line prefer-const
      let maxTime = 60
      const timerId = setInterval(() => {
        if (maxTime <= 0) {
          // 倒计时结束，清除定时器
          clearInterval(timerId)
          // 重置计时器
          this.resetCountdown()
        } else {
          --maxTime
          this.countdown = maxTime
        }
      }, 1000)
      // 标记正在进行倒计时
      this.isCountingDown = true
    },
    // 重置倒计时
    resetCountdown () {
      this.countdown = 60
      this.isCountingDown = false // 标记倒计时结束
    },
    // 通过手机号、验证码登录
    handleRegister () {
      if (this.registerForm.phone === '' || this.registerForm.phone === null || this.registerForm.phone === undefined) {
        return
      }
      if (!this.validatePhoneNumber()) {
        return
      }
      if (this.uuid === '') {
        this.$modal.msgError('验证码发送不成功')
        return
      }
      if (this.registerForm.code === '') {
        this.$modal.msgError('验证码不能为空')
        return
      }
      // const data = {
      //   phone: this.registerForm.phone,
      //   smsCode: parseInt(this.registerForm.code),
      //   uuid: this.uuid
      // }
      const data = {
        phone: this.registerForm.phone,
        smsCode: parseInt(this.registerForm.code),
        smsUuid: this.uuid,
        username: this.registerForm.phone,
        source: 'mobile',
        password: '123456'
        // spKey: 'oE6%tZ7[mX1|'
      }
      smsLoginByCode(data).then(res => {
        // console.log('手机号登录', res)
        // 以下是手机号验证码登录，不适用，修改为
        // setToken(res.token)
        // this.$store.commit('user/SET_TOKEN', res.token)
        // this.$store.dispatch('user/GetInfo')
        // this.$modal.msgSuccess('登录')
        // this.uuid = ''
        this.$modal.msgSuccess('注册成功')
        this.dialogVisible = false
        window.location.href = this.getSigninUrl()
      })
    },
    validatePhoneNumber () {
      // 自定义手机号验证规则
      const phonePattern = /^1[3|4|5|7|8][0-9]{9}$/
      this.phoneNumberIsValid = phonePattern.test(this.registerForm.phone)
      if (!this.phoneNumberIsValid) {
        this.$modal.msgError('请检查手机号')
        return false
      }
      return true
    },
    getShow () {
      if (this.isCountingDown) {
        return this.countdown + '秒'
      }
      if (this.uuid) {
        return '重获验证码'
      }
      return '获取验证码'
    }
  },
  destroyed () {
    clearTimeout(this.timerId) // 清除定时器
  }
}
</script>

<style lang="scss" scoped>
:deep(.login-dialog) {
  border-radius: 12px;
  padding-bottom: 4rem;
  text-align: center;
  .el-dialog__body {
    padding: 10px 44px;
    .logo-box {
      height: 46px;
      width: 155px;
      margin: 0 auto;
    }
    .dialog-title {
      padding-top: 50px;
      padding-bottom: 20px;
      font-size: 18px;
      color: #242424;
      font-weight: 400;
    }
    .login-btn {
      width: 100%;
      height: 42px;
      line-height: 42px;
      background: #66CBE1;
      border-radius: 4px;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 400;
    }
    .sing-in {
      margin: 1.375rem 0 0 0;
    }
    .register {
      margin-top: 0.875rem;
      text-align: left;
    }
  }
}
.form-text {
  text-align: left;
  padding-bottom: 10px;
}
</style>
