import ComLayout from '@/layout'

const modelRouter = {
  path: '/model-management',
  component: ComLayout,
  redirect: '/model',
  meta: { title: 'modelManagement' },
  children: [
    {
      path: '/model',
      component: () => import('@/views/model'),
      name: 'Model',
      meta: { title: 'model' }
    },
    {
      path: '/model/:id',
      component: () => import('@/views/model/detail'),
      name: 'ModelDetail',
      meta: { title: 'modelDetail' },
      props: true
    }
  ]
}

export default modelRouter
