<template>
  <el-image
    :style="`width:${realWidth};height:${realHeight};display: block;`"
    :src="`${realSrc}`"
    :fit="fit"
    rounded="true"
    :class="{bordered: bordered, cartoon: cartoon}"
    >
    <div slot="error" class="image-slot">
      <slot name="error"></slot>
      <!-- <img src="@/assets/personal/test2.png"> -->
    </div>
  </el-image>
</template>

<script>
import { isExternal } from '@/utils/validate'
export default {
  name: 'ImagePreview',
  props: {
    fit: {
      type: String,
      default: 'cover'
    },
    src: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: ''
    },
    height: {
      type: [Number, String],
      default: ''
    },
    bordered: {
      type: Boolean,
      default: false
    },
    cartoon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    realSrc () {
      if (!this.src) {
        return
      }
      const realSrc = this.src.split(',')[0]
      if (isExternal(realSrc)) {
        return realSrc
      }
      return process.env.VUE_APP_BASE_API + realSrc
    },
    realWidth () {
      return typeof this.width === 'string' ? this.width : `${this.width}px`
    },
    realHeight () {
      return typeof this.height === 'string' ? this.height : `${this.height}px`
    },
    realSrcList () {
      if (!this.src) {
        return
      }
      // eslint-disable-next-line prefer-const
      let realSrcList = this.src.split(',')
      // eslint-disable-next-line prefer-const
      let scrList = []
      realSrcList.forEach(item => {
        if (isExternal(item)) {
          return scrList.push(item)
        }
        return scrList.push(process.env.VUE_APP_BASE_API + item)
      })
      return scrList
    }
  }
}
</script>

<style lang="scss" scoped>
.bordered {
  border-radius: 50%;
}
.cartoon {
  :deep(.el-image__inner:hover) {
    transform: scale(1.05);
    transition: all 0.3s;
  }
}
</style>
