import request from '@/utils/requestZhiYa'

// 上传
export function uploadFile (data) {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: '',
    url: '/api/files/uploads',
    method: 'post',
    data
  })
}

// 删除文件
export function deleteFile (data) {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/api/files/delete',
    method: 'delete',
    data
  })
}

// 获取字典参数
export function getDictList (query) {
  return request({
    url: '/home/system/dict/data/all',
    method: 'get',
    params: query
  })
}
