import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import ComLayout from '@/layout'

import courseRouter from './components/course'
import detectionRouter from './components/detection'
import modelRouter from './components/model'
import activityRouter from './components/activity'
import contestRouter from './components/contest'
import newsRouter from './components/news'
import worksRouter from './components/works'
import personalRouter from './components/personal'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    component: ComLayout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home'),
        name: 'HomePage',
        meta: { title: 'homePage' }
      }
    ]
  },
  {
    path: '/',
    component: ComLayout,
    redirect: '/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/home'),
        name: 'HomePage',
        meta: { title: 'homePage' }
      }
    ]
  },
  courseRouter,
  detectionRouter,
  modelRouter,
  activityRouter,
  contestRouter,
  newsRouter,
  worksRouter,
  personalRouter,
  {
    path: '/404',
    component: () => import('@/views/404'),
    name: 'ErrorPage'
  }
]

// 防止连续点击多次路由报错
const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location) {
  return routerReplace.call(this, location).catch(err => err)
}

const createRouter = () => new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export default router
