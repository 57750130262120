<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
      :background="background"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      :page-size.sync="pageSize"
      :current-page.sync="currentPage"
      :pager-count="pagerCount"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      v-bind="$attrs"
      class="my-pagination"
    />
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'PaginationVue',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default () {
        return [10, 20, 30, 50]
      }
    },
    // 移动端页码按钮的数量端默认值5
    pagerCount: {
      type: Number,
      default: document.body.clientWidth < 992 ? 5 : 7
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { }
  },
  computed: {
    currentPage: {
      get () {
        return this.page
      },
      set (val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get () {
        return this.limit
      },
      set (val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange (val) {
      if (this.currentPage * val > this.total) {
        this.currentPage = 1
      }
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange (val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.my-pagination) {
  .btn-prev {
    font-size: 32px;
    background-color: #FFFFFF;
    color: #666666;
    min-width: 2.125rem;
    min-height: 2.125rem;
  }
  .btn-next {
    font-size: 32px;
    background-color: #FFFFFF;
    color: #666666;
    min-width: 2.125rem;
    min-height: 2.125rem;
  }
  .el-pager {
    li {
      background-color: #FFFFFF;
      min-width: 2.125rem;
      min-height: 2.125rem;
      line-height: 2.125rem;
      color: #666666;
      font-size: 0.875rem;
      font-weight: 500;
    }
    li:not(.disabled).active {
      background: #FFFFFF;
      border: 1px solid #00A8CD;
      color: #00A8CD;
      border-radius: 4px;
    }
  }
  .el-pagination__total {
    line-height: 2.125rem;
  }
  .el-pagination__sizes {
    height: 2.125rem;
    line-height: 2.125rem;
    .el-input__inner {
      height: 2.125rem;
    }
  }
  .el-pagination__jump {
    height: 2.125rem;
    line-height: 2.125rem;
    .el-pagination__editor{
      height: 2.125rem;
      .el-input__inner {
        height: 2.125rem;
      }
    }
  }
}
</style>
