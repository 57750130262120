import request from '@/utils/request'

// 获取课程列表
export function getCourseList (query) {
  return request({
    url: '/home/course/list',
    method: 'get',
    params: query
  })
}

// 获取精选好作品
export function getEssenceList (query) {
  return request({
    url: '/home/release/works',
    method: 'get',
    params: query
  })
}

// 获取轮播图
export function getBannerList (query) {
  return request({
    url: '/home/banner/list',
    method: 'get',
    params: query
  })
}

// 获取编程平台
export function getProgramsList (query) {
  return request({
    url: '/home/programs/list',
    method: 'get',
    params: query
  })
}

// 通过userid获取用户荣誉榜
export function getHornorInfo (userId) {
  return request({
    url: '/home/' + userId + '/hornor/info',
    method: 'get'
  })
}

// 查询作品管理列表
export function listHomeWorks (query) {
  return request({
    url: '/home/works/list',
    method: 'get',
    params: query
  })
}
