import ComLayout from '@/layout'

const contestRouter = {
  path: '/contest-management',
  component: ComLayout,
  redirect: '/contest',
  meta: { title: 'contestManagement' },
  children: [
    {
      path: '/contest',
      component: () => import('@/views/contest'),
      name: 'Contest',
      meta: { title: 'contest' }
    },
    {
      path: '/contest/detail/:id',
      component: () => import('@/views/contest/detail'),
      name: 'ContestDetail',
      meta: { title: 'contestDetail' },
      props: true
    },
    {
      path: '/contest/enroll',
      component: () => import('@/views/contest/enroll'),
      name: 'ContestEnroll',
      meta: { title: 'contestEnroll' }
      // props: true
    }
  ]
}

export default contestRouter
