<template>
  <div class="main">
    <nav-bar />
    <keep-alive>
      <router-view />
    </keep-alive>
    <footer-container v-show="isFooter" />
  </div>
</template>

<script>
import NavBar from './NavBar.vue'
import FooterContainer from '@/components/footer-container'
import { getUrlParams } from '@/utils/method.js'
import { mapGetters } from 'vuex'
import { getTokenByCasToken } from '@/api/login-zhiya.js'

export default {
  name: 'VueLayout',
  components: {
    NavBar,
    FooterContainer
  },
  data () {
    return {
      isFooter: false,
      whiteList: ['PersonalWorks', 'ContestDetail', 'ContestEnroll', 'NewsDetail']
    }
  },
  watch: {
    $route: {
      handler: function (newV) {
        if (this.whiteList.includes(newV.name)) {
          this.isFooter = false
        } else {
          this.isFooter = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters([
      'token',
      'cas_token'
    ])
  },
  mounted () {
    const params = getUrlParams(window.location.href)
    if (params.t && params.c) {
      if (this.token || this.cas_token) this.$store.dispatch('user/LogOut')
      getTokenByCasToken({
        casToken: params.c
      })
        .then((response) => {
          if (response.code === 200) {
            this.redirectPage(response, params.t)
          } else {
            this.$message.error('登录失败，请联系管理员')
          }
        })
        .catch(() => {})
    }
  },
  methods: {
    redirectPage (response, type) {
      this.$store.dispatch('user/SetAllToken', response)
        .then((res) => {
          if (res.code === 200) {
            if (type === 'personal') this.$router.push(`/${type}`)
            else {
              this.$router.push({
                path: `/personal/${type}`,
                query: {
                  type: -1
                }
              })
            }
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
.main {
  height: 100%;
}
</style>
