const state = {
  course_video: false
}

const mutations = {
  SET_COURSE_VIDEO: (state, bol) => {
    state.course_video = bol
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
