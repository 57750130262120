import router from './router'
import store from './store'
// import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'

// 路由导航： 判断是否是通过单点登录跳转的带有code和state参数，若是，则换取token和cas_token
// 判断是否获取用户信息，当存在token且未获取用户信息，调用 user/GetInfo 获取用户信息
// 注意： 用户信息未进行本地存储，只存储在store内，页面刷新后会清空，因此，此处获取用户信息不能删除。

router.beforeEach((to, from, next) => {
  // 从单点登录进入
  if (to.query.code && to.query.state) {
    store.dispatch('user/GetPlatformTOken', {
      code: to.query.code,
      state: to.query.state
    }).then(() => {
      router.replace(to.path)
      next()
    }).catch(() => {
      store.dispatch('user/LogOut')
      next()
    })
  }
  // 用户已登录获取用户信息
  const hasToken = getToken()
  if (hasToken && !store.getters.userId) {
    store.dispatch('user/GetInfo')
      .then(() => {
        next()
      })
      .catch(() => {
        store.dispatch('user/LogOut')
        next()
      })
  } else {
    next()
  }
})
