import request from '@/utils/requestZhiYa'

// 登录方法 社区登录
export function login (data) {
  return request({
    url: '/api/user/login',
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register (data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo () {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout (token, casToken) {
  const data = {
    token: token,
    casToken: casToken
  }
  return request({
    url: '/logout',
    method: 'post',
    data: data
  })
}

// 获取验证码
export function getCodeImg () {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 单点登录换取平台token
export function singleLogin (data) {
  return request({
    url: '/cas/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 通过手机号获取验证码
export function getSMSCodeByPhone (data) {
  return request({
    url: '/api/user/login/sms_code',
    method: 'post',
    data: data
  })
}

// 通过短信验证码登录
// export function smsLoginByCode (data) {
//   return request({
//     url: '/api/user/smsLogin',
//     method: 'post',
//     data: data
//   })
// }
export function smsLoginByCode (data) {
  return request({
    url: '/api/user/register',
    method: 'post',
    data: data
  })
}

// casToken换取社区token
export function getTokenByCasToken (data) {
  return request({
    url: '/getTokenByCasToken',
    method: 'post',
    data
  })
}
