import ComLayout from '@/layout'

const newsRouter = {
  path: '/news-management',
  component: ComLayout,
  redirect: '/news',
  meta: { title: 'newsManagement' },
  children: [
    {
      path: '/news',
      component: () => import('@/views/news'),
      name: 'News',
      meta: { title: 'news' }
    },
    {
      path: '/news/detail/:id',
      component: () => import('@/views/news/detail'),
      name: 'NewsDetail',
      meta: { title: 'newsDetail' },
      props: true
    }
  ]
}

export default newsRouter
