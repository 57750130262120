<template>
  <div class="side-menu-container">
    <!-- eslint-disable  -->
    <div class="message-box" v-if="token">
      <img src="@/assets/header/message.svg" alt="">
    </div>

    <div v-if="token" class="avatar-box">
      <AvaterPreview :url="avatar" />
      <ul class="select-menu-box">
        <li v-for="(item, index) in select_menu" :key="index" @click="selectMenuHandler(item)">
          {{item.name}}
        </li>
        <li class="select-menu-box-group" @click="selectMenuHandler({ id: 3 })" v-if="cas_token">
          算力卡
        </li>
        <li @click="selectMenuHandler({ id: -1 })">
          退出登录
        </li>
      </ul>
    </div>
    <div v-if="!token" class="login-box">
      <div v-if="!isLogin">
        <span @click="handleSignIn">登录</span> | <span @click="loginHandle">注册</span>
      </div>
      <div v-if="isLogin">
        <span>登录中...</span>
      </div>
    </div>

    <div class="write-box">
      <div class="write-btn">
        <img src="@/assets/header/write.svg" alt="">创作

        <ul class="write-menu-box" @click="writeMenuHandler">
          <li v-for="(item, index) in write_menu" :key="index" class="write-item" @click="linkHandle(item)">
            <!-- <img :src=item.icon alt=""> -->
            <span class="com-font16">{{item.programName}}</span>
          </li>
        </ul>
      </div>
    </div>
    <userLogin v-if="userVisible" @closeDialog="closeDialog"></userLogin>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userLogin from '../login/index.vue'
import { getProgramsList } from '@/api/home/home.js'
import { getMessageNoRead } from '@/api/message.js'
// import { getUrlParams } from '@/utils/method.js'
export default {
  name: 'SideMenu',
  components: { userLogin },
  data () {
    return {
      select_menu: [
        {
          id: 0,
          name: '作品管理'
        },
        {
          id: 1,
          name: '个人中心'
        },
        {
          id: 2,
          name: '账户设置'
        }
      ],
      write_menu: [
        {
          icon: require('@/assets/header/write-code.svg'),
          name: '图形化'
        },
        {
          icon: require('@/assets/header/write-pythor.svg'),
          name: 'Pythor'
        }
      ],
      userVisible: false,
      isLogin: false
    }
  },
  watch: {
    $route: {
      handler: function (newV) {
        if (newV.query.code && newV.query.state) {
          this.isLogin = true
        } else {
          this.isLogin = false
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters([
      'token',
      'avatar',
      'user',
      'cas_token'
    ])
  },
  created () {
    this.getProgramsListHandle()
  },
  methods: {
    selectMenuHandler (e) {
      // const index = this.select_menu.findIndex(item => item.name === e.target.innerText)
      const index = e.id
      // 0 作品管理 1 个人中心 2 账户设置 3 算力卡 -1 退出
      switch (index) {
        case 0 :
          this.$router.push({
            path: '/personal/works',
            query: {
              type: -1
            }
          })
          return true
        case 1 :
          this.$router.push('/personal')
          return true
        case 2 :
          this.$router.push('/personal/setting')
          return true
        case 3 :
          this.$router.push('/personal/calculus')
          return true
        default :
          this.$confirm('确定注销并退出系统吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$store.dispatch('user/LogOut').then(() => {
              location.href = '/home'
            })
          }).catch(() => {})
          return false
      }
    },
    // 点击创作
    writeMenuHandler (e) {},
    // 登录/注册
    loginHandle () {
      this.userVisible = true
    },
    closeDialog (item) {
      // console.log('closeDialog')
      this.userVisible = false
    },
    getProgramsListHandle () {
      getProgramsList({ status: 1 }).then(res => {
        // console.log('编程平台', res)
        // 只取前六个
        this.write_menu = res.rows.slice(0, 6)
        this.$store.commit('program/SET_PROGRAM_DATA', this.write_menu)
      })
    },
    // 平台跳转
    linkHandle (item) {
      window.open(item.link, '_blank')
    },
    // 获取用户接收到的消息
    getMessageListHandle () {
      getMessageNoRead().then(res => {
        // console.log('获取用户接收到的消息', res)
      })
    },
    // 单点登录
    handleSignIn () {
      window.location.href = this.getSigninUrl()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  background-color: unset !important;
}
.el-menu--horizontal>.el-menu-item {
  border-bottom: none !important;
}
.side-menu-container {
  // margin: 0 0 0 13.1875rem;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  .message-box {
    width: 3.875rem;
    height: 3.75rem;
    & > img {
      width: 2.25rem;
      height: 2.25rem;
      margin: 0 auto;
      padding: .75rem 0;
    }
  }
  .avatar-box {
    width: 3.875rem;
    height: 3.75rem;
    position: relative;
    & > img {
      width: 2.375rem;
      height: 2.375rem;
      margin: 0 auto;
      padding: .75rem 0;
      border-radius: 1.125rem;
      border-radius: 50%;
    }
    .select-menu-box {
      visibility: hidden;
      width: 9.75rem;
      height: auto;
      position: absolute;
      padding: .3125rem 0;
      border-radius: .375rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px .75rem .0625rem $Base-Menu-Shadow;
      cursor: pointer;
      z-index: 1001;
      & > li {
        width: 9.75rem;
        height: 2.375rem;
        padding: 0 0 0 20px;
        text-align: left;
        line-height: 2.375rem;
        box-sizing: border-box;
        font-size: .875rem;
        font-weight: 400;
        font-family: Source Han Sans CN-Regular;
        color: rgba(51, 51, 51, 1);
      }
      & > li:last-of-type::before {
        content: '';
        display: block;
        width: 85%;
        border-bottom: 1px solid rgba(220, 220, 220, 1);
      }
      & > li:hover {
        background: $Base-Menu-Item-Act;
      }
      .select-menu-box-group::before {
        content: '';
        display: block;
        width: 85%;
        border-bottom: 1px solid rgba(220, 220, 220, 1);
      }
    }
  }
  .avatar-box:hover {
    .select-menu-box {
      visibility: visible;
    }
  }
  .login-box {
    height: 3.75rem;
    line-height: 3.75rem;
    color: #fff;
    // width: 4rem;
    text-align: left;
    margin-right: 1rem;
  }
  .write-box {
    height: 3.75rem;
    position: relative;
    .write-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6.25rem;
      height: 2.5rem;
      margin: .625rem 0;
      border-radius: 1.625rem;
      font-size: 1.25rem;
      font-weight: 400;
      font-family: Source Han Sans CN-Regular;
      color: rgba(255, 255, 255, 1);
      background: $Base-Menu-Write-Btn;
      & > img {
        width: 1.375rem;
        height: 1.375rem;
      }
      .write-menu-box {
        visibility: hidden;
        position: absolute;
        top: 3.8125rem;
        width: 11rem;
        height: auto;
        padding: .3125rem 0;
        border-radius: 6px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 12px 1px rgba(120, 105, 48, 0.27);
        z-index: 1001;
        .write-item {
          display: flex;
          align-items: center;
          height: 40px;
          & > img {
            width: 1.625rem;
            height: 1.625rem;
            margin: 0 .625rem;
          }
          & > span {
            color: rgba(51, 51, 51, 1);
            margin-left: 1rem;
          }
        }
        .write-item:hover {
          background: $Base-Menu-Item-Act;
        }
      }
    }
  }
  .write-box:hover {
    .write-menu-box {
      visibility: visible;
    }
  }
  // .write-btn:active {
  //   transform: scale(0.8);
  // }
}
</style>
