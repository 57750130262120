/* eslint-disable */
export const config = {
  redirectPath: "/index", // 线上 in accordance with casdoor configuration
  serverUrl: 'https://caszs.codespore.cn',
  endpoint: 'https://caszs.codespore.cn',
  clientId: '68a85a033deee95645d9',
  clientSecret: '0916ee606641013b35d63b3a419cd87ba546a074',
  certificate: 'soke',
  organizationName: 'soke',
  applicationName: 'zhiya',
}
