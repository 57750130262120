<template>
  <div class="mask-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MaskContainer'
}
</script>

<style lang="scss" scoped>
.mask-container {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: rgba(0, 0, 0, .4);
}
</style>
