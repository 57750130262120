import ComLayout from '@/layout'

const detectionRouter = {
  path: '/detection-management',
  component: ComLayout,
  redirect: '/detection',
  meta: { title: 'detectionManagement' },
  children: [
    {
      path: '/detection',
      component: () => import('@/views/detection'),
      name: 'Detection',
      meta: { title: 'detection' }
    },
    {
      path: '/detection/comment/:id',
      component: () => import('@/views/detection/comment'),
      name: 'DetectionComment',
      meta: { title: 'detectionComment' },
      props: true
    }
  ]
}

export default detectionRouter
