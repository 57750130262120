<template>
  <div class="footer-container">
    <div class="footer">
      <div class="info">
        <div class="logo">
          <div>
            <img src="@/assets/footer/logo.svg" alt="">
            <img src="@/assets/footer/logo-name.svg" alt="">
          </div>
          <img src="@/assets/footer/text.png" alt="">
        </div>

        <div class="link">
          <div>友情链接</div>
          <div>
            <div v-for="(item, index) in link" :key="index">
              <p v-for="(child, index1) in item" :key="index1" @click="linkHandle(child)" class="pointer">
                {{ child.dictLabel }}
              </p>
            </div>
          </div>
        </div>

        <div class="about">
          <p>关注我们</p>
          <div class="code">
            <div v-for="(item, index) in about" :key="index" class="code-item">
              <image-preview :src="item.icon" :width="'6.875rem'" :height="'6.875rem'">
                <template #error>
                    <img src="@/assets/footer/code.svg">
                </template>
              </image-preview>
              <p>{{ item.dictLabel }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="contact">
        <div v-for="(item, index) in company" :key="index">{{item.dictLabel}} <span v-html="item.dictValue" class="contact-text"></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDictList } from '@/api/system.js'
export default {
  name: 'FooterContainer',
  data () {
    return {
      // 关于我们
      about: [
        {
          name: 'wx',
          dictLabel: '微信扫码关注公众号',
          icon: require('@/assets/footer/code.svg')
        },
        {
          name: 'wb',
          dictLabel: '微博扫码关注微博号',
          icon: require('@/assets/footer/code.svg')
        },
        {
          name: 'dy',
          dictLabel: '抖音扫码关注抖音号',
          icon: require('@/assets/footer/code.svg')
        }
      ],
      // 公司基本信息
      company: [{
        dictLabel: '客服电话：',
        dictValue: '0769-26623129（9:30~18:00）'
      }, {
        dictLabel: '公司地址：',
        dictValue: '广东省东莞市松山湖万象汇3楼315'
      }, {
        dictLabel: '版权所有 ',
        dictValue: '粤ICP备XXXXXXXXXXXX@2021 东莞市松山湖叹奇培训中心有限公司'
      }],
      // 友情连接
      link: [
        [{
          dictLabel: '青少年机器人技术等级考试',
          dictValue: 'https://www.qceit.org.cn/bos/default.html?d=7968.563329401135'
        }, {
          dictLabel: '青少年软件编程等级考试',
          dictValue: 'https://www.ycltest.com/detail/index664.html?type=4'
        }, {
          dictLabel: '青少年人工智能技术水平测试',
          dictValue: 'http://www.yaie.com.cn/'
        }, {
          dictLabel: '青少年人工智能编程水平测试',
          dictValue: 'http://www.yaie.com.cn/'
        }],
        [{
          dictLabel: '蓝桥青少',
          dictValue: 'https://www.lanqiaoqingshao.cn/home'
        }, {
          dictLabel: '世界机器人大赛',
          dictValue: 'https://www.worldrobotconference.com/'
        }, {
          dictLabel: '全国中小学信息技术创新与实践大赛',
          dictValue: 'http://s.noc.net.cn/'
        }, {
          dictLabel: '全国青少年信息学奥林匹克竞赛',
          dictValue: 'https://www.noi.cn/'
        }, {
          dictLabel: '全国青少年人工智能创新挑战赛',
          dictValue: 'http://aiic.china61.org.cn/'
        }]
      ]
    }
  },
  created () {
    this.getList()
  },
  methods: {
    /* 跳转页面 */
    linkHandle (item) {
      window.open(item.dictValue, '_blank')
    },
    /* 获取footer 字典数据 */
    getList () {
      getDictList({ dictType: 'zhiya_footer_system' }).then(res => {
        if (res.code === 200 && res.total > 0) {
          const deptOptions = this.handleTree(res.rows, 'dictCode', 'pid', 'children')
          deptOptions.forEach(element => {
            if (element.dictValue === 'company') {
              this.company = element.children
            }
            if (element.dictValue === 'about') {
              this.about = element.children
            }
            if (element.dictValue === 'link') {
              const list = element.children
              this.link = []
              list.forEach(item => {
                this.link.push(item.children)
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style >
a {
  color: rgba(153, 153, 153, 1);
  text-decoration: none;
}
a:hover {
  color: #222;
}
</style>

<style lang="scss" scoped>
.footer-container {
  width: 100%;
  min-width: 88.125rem;
  background: $Base-Footer-Bg;
  .footer {
    width: 100%;
    margin: 0 auto;
    padding: 1.8125rem 0;
    .info {
      display: flex;
      justify-content: space-around;
      .logo {
        display: flex;
        flex-direction: column;
        & > div:nth-child(1) {
          display: flex;
          align-items: center;
        }
        & > img {
          width: 22.0625rem;
          height: 4.875rem;
          margin: .6875rem 0 0 0;
        }
        & > div > img:nth-child(1) {
          width: 2.25rem;
          height: 2.125rem;
        }
        & > div > img:nth-child(2) {
          width: 6.5625rem;
          height: 1.75rem;
        }
      }
      .link {
        text-align: left;
        & > div:nth-child(1) {
          font-size: 1rem;
          font-weight: 300;
          font-family: Source Han Sans CN-Medium;
          color: rgba(102, 102, 102, 1);
        }
        & > div:nth-child(2) {
          display: flex;
          margin: 1rem 0 0 0;
          & > div {
            display: flex;
            flex-direction: column;
            & > p {
              margin: 1rem 0 0 0;
              font-size: .875rem;
              font-weight: 300;
              font-family: Source Han Sans CN-Light;
              color: rgba(153, 153, 153, 1);
            }
          }
          & > div:nth-child(1) {
            margin: 0 6.1875rem 0 0;
          }
        }
      }
      .about {
        & > p {
          margin: 0 0 .625rem 0;
          text-align: center;
          font-size: 1rem;
          font-weight: 400;
          font-family: Source Han Sans CN-Medium;
          color: rgba(102, 102, 102, 1);
        }
        .code {
          display: flex;
          justify-content: space-between;
          .code-item {
            margin: 0 1.5rem 0 0;
            & > img {
              width: 6.875rem;
              height: 6.875rem;
              margin: 0 auto;
            }
            & > p {
              margin: .625rem 0 0 0;
              font-size: .875rem;
              font-weight: 300;
              font-family: Source Han Sans CN-Light;
              color: rgba(153, 153, 153, 1);
            }
          }
        }
      }
    }
    .contact {
      display: flex;
      justify-content: space-around;
      margin: 2.625rem 0 0 0;
      font-size: .875rem;
      font-weight: 300;
      font-family: Source Han Sans CN-Light;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
