<template>
  <div>
    <Tinyeditor
      v-model="tinymceHtml"
      class="sk-tinyeditor"
      :disabled="disabled"
      :init="init"
      @onClick="onClick"
    ></Tinyeditor>
  </div>
</template>

<script>
import axios from 'axios'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import Editor from '@tinymce/tinymce-vue'

import 'tinymce/plugins/advlist' // 高级列表
import 'tinymce/plugins/anchor' // 锚点
import 'tinymce/plugins/autolink' // 自动链接
import 'tinymce/plugins/autoresize' // 编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import 'tinymce/plugins/autosave' // 自动存稿

import 'tinymce/plugins/charmap' // 特殊字符
import 'tinymce/plugins/code' // 编辑源码
import 'tinymce/plugins/codesample' // 代码示例

import 'tinymce/plugins/directionality' // 文字方向
import 'tinymce/plugins/emoticons' // 表情
// import 'tinymce/plugins/fullpage'  //文档属性
import 'tinymce/plugins/fullscreen' // 全屏
import 'tinymce/plugins/help' // 帮助
import 'tinymce/plugins/hr' // 水平分割线
import 'tinymce/plugins/image' // 插入编辑图片
import 'tinymce/plugins/importcss' // 引入css
import 'tinymce/plugins/insertdatetime' // 插入日期时间
import 'tinymce/plugins/link' // 超链接
import 'tinymce/plugins/lists' // 列表插件
import 'tinymce/plugins/media' // 插入编辑媒体
import 'tinymce/plugins/nonbreaking' // 插入不间断空格
import 'tinymce/plugins/pagebreak' // 插入分页符
import 'tinymce/plugins/paste' // 粘贴插件
import 'tinymce/plugins/preview' // 预览
import 'tinymce/plugins/print' // 打印
// import 'tinymce/plugins/quickbars'  //快速工具栏
// import 'tinymce/plugins/save'  //保存
import 'tinymce/plugins/searchreplace' // 查找替换
// import 'tinymce/plugins/tabfocus'  //切入切出，按tab键切出编辑器，切入页面其他输入框中
import 'tinymce/plugins/table' // 表格
import 'tinymce/plugins/template' // 内容模板
import 'tinymce/plugins/textpattern' // 快速排版
// import 'tinymce/plugins/toc'  //目录生成器
import 'tinymce/plugins/visualblocks' // 显示元素范围
import 'tinymce/plugins/visualchars' // 显示不可见字符
import 'tinymce/plugins/wordcount' // 字数统计
import '../../../public/tinymce/indent2em' // 首行缩进
import '../../../public/tinymce/formulas' // 公式编辑
// import '../../../public/tinymce/bdmap' // 百度地图
import '../../../public/tinymce/axupimgs' // 多图上传

import { getToken } from '@/utils/auth'

export default {
  name: 'TinyEditor',
  components: { Tinyeditor: Editor },
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fileSize: {
      type: Number,
      default: 2
    },
    plugins: {
      type: [String, Array],
      default: `help print preview searchreplace autolink directionality emoticons indent2em visualblocks 
        visualchars fullscreen image axupimgs link media template code codesample table 
        charmap hr pagebreak nonbreaking anchor insertdatetime advlist 
        lists wordcount textpattern autosave autoresize kityformula-editor`
    },
    toolbar: {
      type: [String, Array],
      // default: `
      //   fullscreen undo redo restoredraft |
      //   forecolor backcolor bold italic underline strikethrough link anchor |
      //   alignleft aligncenter alignright alignjustify outdent indent |
      //   formatselect fontselect fontsizeselect |
      //   bullist numlist |
      //   blockquote subscript superscript removeformat |
      //   table image media charmap emoticons hr pagebreak insertdatetime print preview |
      //   code selectall searchreplace visualblocks | cut copy paste pastetext | styleselect`
      default: function () {
        return [
          'fullscreen undo redo restoredraft | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | formatselect styleselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | table image axupimgs media charmap emoticons hr pagebreak insertdatetime print preview | code selectall searchreplace visualblocks | cut copy paste pastetext | indent2em kityformula-editor'
        ]
      }
    }
  },
  data () {
    return {
      tinymceHtml: this.value,
      init: {
        // inline: true,
        selector: '.sk-tinyeditor',
        language_url: '/tinymce/zh_CN.js', // 引入语言包文件
        language: 'zh_CN', // 语言类型
        skin_url: '/tinymce/skins/ui/oxide', // 皮肤：浅色
        height: 500, // 使用了 autoresize 高度自适应 此属性失效
        emoticons_database_url: '/tinymce/emoticons/js/emojis.js',
        content_css: '/tinymce/skins/content/default/content.css',
        content_style: 'img {max-width:100%;}', // 直接自定义可编辑区域的css样式
        // plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu',
        // toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat',
        plugins: this.plugins, // 插件配置
        toolbar: this.toolbar, // 工具栏 设置为false 则隐藏
        resize: true, // 编辑器宽高是否可变，false-否,true-高可变，'both'-宽高均可，注意引号
        placeholder: '在这里输入文字',
        branding: false, // tiny技术支持信息是否显示
        elementpath: true, // 元素路径是否显示
        paste_data_images: true, // 图片是否可粘贴
        relative_urls: false, // 路径保持
        convert_urls: false, // 转化为绝对路径
        // images_upload_url: process.env.VUE_APP_BASE_API + '/api/files/uploads',
        // images_upload_base_path: process.env.VUE_APP_BASE_API ,
        file_picker_types: 'link, image, media', // 分别对应三个类型文件的上传：link插件，image和axupimgs插件，media插件。想屏蔽某个插件的上传就去掉对应的参数
        images_upload_handler: (blobInfo, success, failure) => {
          if (blobInfo.blob().size / 1024 / 1024 > this.fileSize) {
            failure(`上传失败, 图片大小应控制在 ${this.fileSize}M 以内`)
          } else {
            // eslint-disable-next-line prefer-const
            let params = new FormData()
            params.append('file', blobInfo.blob())
            params.append('path', '/image')
            const config = {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + getToken()
              }
            }
            axios
              .post(
                process.env.VUE_APP_BASE_API + '/api/files/uploads',
                params,
                config
              )
              .then((res) => {
                if (res.data.code === 200) {
                  success(this.zhiyaBaseUrl + res.data.path)
                } else {
                  failure('上传出错， 服务器开小差了，请联系管理员')
                }
              })
              .catch(() => {
                // console.log('error', error)
                failure('上传出错， 服务器开小差了，请联系管理员')
              })
          }
        },
        file_picker_callback: (callback, value, meta) => {
          const filetype =
            '.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4, .jpg, .sk, .png' // 限制文件的上传类型
          // eslint-disable-next-line prefer-const
          let inputElem = document.createElement('input') // 创建文件选择
          inputElem.setAttribute('type', 'file')
          inputElem.setAttribute('accept', filetype)
          inputElem.click()
          inputElem.onchange = () => {
            let upurl = ''
            // eslint-disable-next-line prefer-const
            let file = inputElem.files[0] // 获取文件信息
            if (file.type.slice(0, 5) === 'video') {
              // 判断文件类型
              upurl = process.env.VUE_APP_BASE_API + '/api/files/uploads'
            } else {
              upurl = process.env.VUE_APP_BASE_API + '/api/files/uploads'
            }
            if (
              file.type.slice(0, 5) === 'image' &&
              file.size / 1024 / 1024 > 5
            ) {
              alert('上传失败，图片大小请控制在5M以内')
            } else if (
              file.type.slice(0, 5) === 'video' &&
              file.size / 1024 / 1024 > 200
            ) {
              alert('上传失败，视频大小请控制在 200M 以内')
            } else if (file.size / 1024 / 1024 > 10) {
              alert('上传失败，文件大小请控制在 10M 以内')
            } else {
              // eslint-disable-next-line prefer-const
              let params = new FormData()
              params.append('file', file)
              params.append('path', '/file')
              const config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + getToken()
                }
              }
              axios
                .post(upurl, params, config)
                .then((res) => {
                  if (res.data.code === 200) {
                    // callback(res.data.path)  //上传成功，在回调函数里填入文件路径
                    if (meta.filetype === 'file') {
                      // eslint-disable-next-line node/no-callback-literal
                      callback(this.zhiyaBaseUrl + res.data.path)
                    }
                    if (meta.filetype === 'image') {
                      // eslint-disable-next-line node/no-callback-literal
                      callback(this.zhiyaBaseUrl + res.data.path)
                    }
                    if (meta.filetype === 'media') {
                      // eslint-disable-next-line node/no-callback-literal
                      callback(this.zhiyaBaseUrl + res.data.path)
                    }
                  } else {
                    alert('上传失败')
                  }
                })
                .catch(() => {
                  alert('上传出错，服务器开小差了呢')
                })
            }
          }
          // if (meta.filetype === 'file') {
          //   callback('mypage.html', {text: 'My text'});
          // }
          // if (meta.filetype === 'image') {
          //   callback('myimage.jpg', {alt: 'My alt text'});
          // }
          // if (meta.filetype === 'media') {
          //   callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
          // }
        }
      },
      zhiyaBaseUrl: ''
    }
  },
  mounted () {
    tinymce.init({})
  },
  created () {
    this.getConfigKey('zhiya.base.url').then((response) => {
      this.zhiyaBaseUrl = response.msg
    })
  },
  watch: {
    value (newValue) {
      this.tinymceHtml = newValue
    },
    tinymceHtml (newValue) {
      this.$emit('input', newValue)
    }
  },
  methods: {
    onClick (e) {
      this.$emit('onClick', e, tinymce)
    },
    // 清空内容
    clear () {
      this.tinymceHtml = ''
    }
  }
}
</script>
