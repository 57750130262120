import ComLayout from '@/layout'
import { getToken } from '@/utils/auth'

const personalRouter = {
  path: '/personal-management',
  component: ComLayout,
  redirect: '/personal',
  meta: { title: 'personalManagement' },
  beforeEnter: (to, from, next) => {
    const hasToken = getToken()
    if (!hasToken) {
      next('/404')
    } else {
      next()
    }
  },
  children: [
    {
      path: '/personal',
      component: () => import('@/views/personal'),
      name: 'PersonalManagement',
      meta: { title: 'personalManagement' }
    },
    {
      path: '/personal/works',
      component: () => import('@/views/personal/works'),
      name: 'PersonalWorks',
      meta: { title: 'personalWorks' }
    },
    {
      path: '/personal/setting',
      component: () => import('@/views/personal/setting'),
      name: 'PersonalSetting',
      meta: { title: 'personalSetting' }
    },
    {
      path: '/personal/calculus',
      component: () => import('@/views/personal/calculus'),
      name: 'PersonalCalculus',
      meta: { title: 'personalCalculus' }
    }
  ]
}

export default personalRouter
