import ComLayout from '@/layout'

const courseRouter = {
  path: '/course-management',
  component: ComLayout,
  redirect: '/course',
  meta: { title: 'courseManagement' },
  children: [
    {
      path: '/course',
      component: () => import('@/views/course'),
      name: 'Course',
      meta: { title: 'course' }
    }
  ]
}

export default courseRouter
