<template>
  <div class="nav-container">
    <div class="header">
      <img src="@/assets/header/logo.svg" alt="">

      <div class="nav-list" >
        <template v-for="(item, index) in nav_list">
          <router-link :key=index :to=item.link>{{ item.name }}</router-link>
        </template>
      </div>

      <side-menu />
    </div>
  </div>
</template>

<script>
import SideMenu from './components/SideMenu'

export default {
  name: 'NavBar',
  components: {
    SideMenu
  },
  data () {
    return {
      nav_list: [
        {
          name: '首页',
          link: '/home'
        },
        {
          name: '发现',
          link: '/detection'
        },
        {
          name: '课程',
          link: '/course'
        },
        {
          name: '模型',
          link: '/model'
        },
        {
          name: '活动',
          link: '/activity'
        },
        {
          name: '赛事',
          link: '/contest'
        },
        {
          name: '新闻',
          link: '/news'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-container {
  width: 100%;
  min-width: 88.125rem;
  height: 3.75rem;
  margin: 0 auto;
  background: $Base-Nav-Bg;
  .header {
    display: flex;
    width: calc(100% - 20.1875rem);
    min-width: 65.375rem;
    height: inherit;
    margin: 0 auto;
    & > img {
      width: 8.9375rem;
      height: 2.125rem;
      padding: .8125rem 0;
    }
    .nav-list {
      display: flex;
      margin: 0 0 0 2.5rem;
      & > a {
        display: block;
        width: 5rem;
        height: 3.75rem;
        line-height: 3.75rem;
        font-size: 1.25rem;
        font-weight: 400;
        font-family: Source Han Sans CN-Regular;
        text-align: center;
        text-decoration: none;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.router-link-active {
  background: $Base-Nav-Act;
}
</style>
