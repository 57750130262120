// 判断是否是符合 (https?:|mailto:|tel:) 条件的图片路径
export function isRealPath (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
// 拼接图片完整路径
export function getRealSrc (src) {
  if (!src) {
    return
  }
  const realSrc = src.split(',')[0]
  if (isRealPath(realSrc)) {
    return realSrc
  }
  return process.env.VUE_APP_BASE_API + realSrc
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree (data, id, parentId, children) {
  // eslint-disable-next-line prefer-const
  let config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children'
  }
  // eslint-disable-next-line prefer-const
  let childrenListMap = {}
  // eslint-disable-next-line prefer-const
  let nodeIds = {}
  // eslint-disable-next-line prefer-const
  let tree = []
  // eslint-disable-next-line prefer-const
  for (let d of data) {
    // eslint-disable-next-line prefer-const
    let parentId = d[config.parentId]
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = []
    }
    nodeIds[d[config.id]] = d
    childrenListMap[parentId].push(d)
  }
  // eslint-disable-next-line prefer-const
  for (let d of data) {
    // eslint-disable-next-line prefer-const
    let parentId = d[config.parentId]
    if (nodeIds[parentId] == null) {
      tree.push(d)
    }
  }
  // eslint-disable-next-line prefer-const
  for (let t of tree) {
    adaptToChildrenList(t)
  }

  function adaptToChildrenList (o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]]
    }
    if (o[config.childrenList]) {
      // eslint-disable-next-line prefer-const
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c)
      }
    }
  }
  return tree
}
