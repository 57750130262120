const state = {
  program_data: []
}

const mutations = {
  SET_PROGRAM_DATA: (state, rows) => {
    state.program_data = rows
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
