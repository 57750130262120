<template>
  <mask-container>
    <div class="video-dialog">
      <div class="title">
        <span class="com-font14">{{video_data.title}}</span>
        <img src="@/assets/dialog/close.svg" alt="" @click="closeHandler">
      </div>
      <div class="course-center">
        <video class="video" :src="video_data.src" controls></video>
        <div class="video-menu">
          <div class="course-ware" @click="routeHandle">播放课件</div>
        </div>
      </div>
      <div class="follow">
        <div>
          <p class="com-font16">{{video_data.title}}</p>
          <span class="com-font12">点击右侧「边看边做」按钮进入源码编辑器边学边做</span>
        </div>

        <input type="button" value="开始练习" @click="linkHandle">
      </div>
    </div>
  </mask-container>
</template>

<script>
import MaskContainer from '@/components/mask-container'
import { Base64 } from 'js-base64'
import { getConfigKey } from '@/api/config'

export default {
  name: 'videoDilag',
  props: {
    video_data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      fileurl: '',
      previewurl: ''
    }
  },
  created () {
    getConfigKey('fileurl').then(response => {
      this.fileurl = response.msg
    })
    getConfigKey('previewurl').then(response => {
      this.previewurl = response.msg
    })
  },
  components: {
    MaskContainer
  },
  methods: {
    closeHandler () {
      this.$store.commit('dialog/SET_COURSE_VIDEO', false)
      this.$emit('closeHandler')
    },
    // 开始练习
    linkHandle () {
      const from = 'zhiya'
      const releaseId = this.video_data.workData.workId
      window.open(`${this.video_data.workData.programData.link}?from=${from}&releaseId=${releaseId}`, '_blank')
    },
    routeHandle () {
      const openUrl = this.previewurl + '/onlinePreview?url=' + encodeURIComponent(Base64.encode(this.fileurl + this.video_data.courseWare))
      window.open(openUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.video-dialog {
  display: inline-block;
  width: 45rem;
  height: 27rem;
  margin: 16.75rem 0 0 0;
  border-radius: .5rem;
  text-align: left;
  vertical-align: middle;
  z-index: 10;
  background-color: #fff;
  background: #fff;
  box-shadow: 0 6px 28px 0 rgba(0,0,0,.12);
  .course-center {
    position: relative;
    .video {
      width: 100%;
      height: 25.125rem;
      border-radius: 0 0 .5rem .5rem;
    }
    .video-menu {
      position: absolute;
      top: 1rem;
      width: 4rem;
      height: 2rem;
      left: 2rem;
      color: rgb(255, 30, 16);
      display: none;
      .course-ware {
        cursor: pointer;
      }
    }
  }
  .course-center:hover {
    .video-menu {
      display: block;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.875rem;
    padding: 0 .375rem 0 .875rem;
    & > span {
      color: rgba(36, 36, 36, 1);
    }
    & > img {
      width: 1.375rem;
      height: 1.375rem;
    }
  }
  .follow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    & > div {
      margin: 0 0 0 1.1875rem;
      & > p {
        margin: 0 0 .625rem;
        color: rgba(51, 51, 51, 1);
      }
    }
    & > input {
      width: 8.75rem;
      height: 2.25rem;
      margin: 0 1.1875rem 0 0;
      border: none;
      border-radius: .25rem;
      background: rgba(0, 168, 205, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
