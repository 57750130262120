import { getToken, setToken, removeToken, getCasToken, setCasToken, removeCasToken } from '@/utils/auth'
import { login, logout, getInfo, singleLogin } from '@/api/login-zhiya'

const state = {
  token: getToken(),
  id: '',
  name: '',
  avatar: '',
  roles: [],
  permissions: [],
  deptId: 0,
  profile: {},
  cas_token: getCasToken(),
  user: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
    state.user.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_DEPTID: (state, deptId) => {
    state.deptId = deptId
  },
  SET_PROFILE: (state, profile) => {
    state.profile = profile
  },
  SET_CAS_TOKEN: (state, token) => {
    state.cas_token = token
  },
  SET_USER: (state, user) => {
    state.user = user
  }
}

const actions = {
  // 登录
  Login ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then(res => {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // 获取用户信息
  GetInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(res => {
        const user = res.user
        const avatar = (user.avatar === '' || user.avatar == null) ? require('@/assets/header/default-avatar.svg') : user.avatar
        if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
          commit('SET_ROLES', res.roles)
          commit('SET_PERMISSIONS', res.permissions)
        } else {
          commit('SET_ROLES', ['ROLE_DEFAULT'])
        }
        commit('SET_ID', user.userId)
        commit('SET_NAME', user.userName)
        commit('SET_DEPTID', user.deptId)
        commit('SET_USER', user)
        commit('SET_AVATAR', avatar)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 退出系统
  LogOut ({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token, state.cas_token).then(() => {
        commit('SET_CAS_TOKEN', '')
        commit('SET_TOKEN', '')
        removeToken()
        removeCasToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // cas_token换取平台token
  GetPlatformTOken ({ dispatch, commit, state }, params) {
    return new Promise((resolve, reject) => {
      singleLogin(params)
        .then(res => {
          commit('SET_CAS_TOKEN', res.casToken)
          commit('SET_TOKEN', res.token)
          setToken(res.token)
          setCasToken(res.casToken)
          // setTimeout(() => {
          //   dispatch('GetInfo')
          // })

          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  SetAllToken ({ dispatch, commit, state }, params) {
    commit('SET_CAS_TOKEN', params.casToken)
    commit('SET_TOKEN', params.token)
    setToken(params.token)
    setCasToken(params.casToken)

    return new Promise((resolve, reject) => {
      resolve(dispatch('GetInfo'))
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
