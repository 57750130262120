<template>
  <div class="nothing">
    <img :src="url" alt="">
    <span class="com-font16">{{ text }}</span>
  </div>
</template>

<script>
import url from '@/assets/empty/no-work.svg'
export default {
  name: 'EmptyContainer',
  props: {
    text: {
      type: String,
      default: '暂无数据'
    },
    url: {
      type: String,
      default: url
    }
  }
}
</script>

<style lang="scss" scoped>
.nothing {
  min-height: 20rem;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > img {
    width: 12.875rem;
    height: 9.875rem;
    margin: 0 0 1.25rem 0;
  }
  & > span {
    color: rgba(187, 187, 187, 1);
  }
}
</style>
