import ComLayout from '@/layout'

const worksRouter = {
  path: '/works-management',
  component: ComLayout,
  redirect: '/works',
  meta: { title: 'worksManagement' },
  children: [
    {
      path: '/works/:id',
      component: () => import('@/views/works'),
      name: 'Works',
      meta: { title: 'works' },
      props: true
    }
  ]
}

export default worksRouter
