import request from '@/utils/requestZhiYa'

// 获取用户接收到的消息
export function getMessageList (query) {
  return request({
    url: '/messages/user/tablution',
    method: 'get',
    params: query
  })
}

// 获取用户未读消息数据
export function getMessageNoRead (query) {
  return request({
    url: '/home/message/noRead',
    method: 'get',
    params: query
  })
}
