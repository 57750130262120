const getters = {
  token: state => state.user.token,
  cas_token: state => state.user.cas_token,
  course_video: state => state.dialog.course_video,
  avatar: state => state.user.avatar,
  userId: state => state.user.id,
  profile: state => state.user.profile,
  name: state => state.user.name,
  user: state => state.user.user,
  program_data: state => state.program.program_data
}

export default getters
