import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 单点登录
import Casdoor from 'casdoor-vue-sdk'
import { config } from '../cas.setting.js'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/element.scss'
// 图片预览组件
import ImagePreview from '@/components/ImagePreview'
// 分页组件
import Pagination from '@/components/Pagination'
// 播放组件
import VideoDilag from '@/components/video'
// 用户头像组件
import AvaterPreview from '@/components/AvaterPreview'
// 图片上传组件
import ImageUpload from '@/components/ImageUpload'
// 文件上传组件
import FileUpload from '@/components/FileUpload'
// 空数据组件
import EmptyContainer from '@/components/EmptyContainer'

import TinyEditor from '@/components/TinyEditor'

import './style/index.css'
import './style/index.scss'
import './permission.js'

import { getRealSrc, handleTree } from '@/utils/generic.js'
import { parseTime, getFloorUnit } from '@/utils/method.js'
import { getConfigKey } from '@/api/config.js'
import plugins from '@/plugins/index.js' // plugins

// 过滤器
Vue.filter('IMAGE_FILTER', (path) => { return process.env.VUE_APP_BASE_API + path })
Vue.filter('PARSE_TIME', (time) => { return parseTime(time) })
Vue.filter('PARSE_TIME_SIMPLE', (time) => { return parseTime(time, '{y}-{m}-{d}') })

Vue.config.productionTip = false
Vue.prototype.getRealSrc = getRealSrc
Vue.prototype.handleTree = handleTree
Vue.prototype.getFloorUnit = getFloorUnit
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
// 全局组件挂载
Vue.component('ImagePreview', ImagePreview)
Vue.component('Pagination', Pagination)
Vue.component('VideoDilag', VideoDilag)
Vue.component('AvaterPreview', AvaterPreview)
Vue.component('ImageUpload', ImageUpload)
Vue.component('FileUpload', FileUpload)
Vue.component('EmptyContainer', EmptyContainer)
Vue.component('TinyEditor', TinyEditor)

Vue.use(plugins)
Vue.use(ElementUI)
Vue.use(Casdoor, config)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
