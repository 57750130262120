<template>
  <img ref="myImage" :src="url | IMAGE_FILTER" :alt="alt" @error="loadDefaultImage">
</template>

<script>
export default {
  name: 'AvaterPreview',
  props: {
    url: {
      type: String
    },
    alt: {
      type: String,
      default: '加载失败'
    }
  },
  created () {
    // console.log('avaterPreview:', this.img)
  },
  methods: {
    // 用户头像加载失败时加载默认头像
    loadDefaultImage () {
      this.$refs.myImage.src = require('@/assets/header/default-avatar.svg')
    }
  }
}
</script>
